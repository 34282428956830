/* ======= VARIABLES ======= */

$fa-font-path:	"fonts";

$enable-rounded: false;
$primary: #0B51B3; 
$secondary: #D60F3C;
$dark: #101010;
$light: #f3f3f3;
$font-family-sans-serif: 'Open Sans', sans-serif;
$headings-font-family: 'Roboto', sans-serif;
$font-size-base: 1rem;
$h1-font-size: $font-size-base * 2.25;
$h2-font-size: $font-size-base * 1.8;
$h3-font-size: $font-size-base * 1.45;
$h4-font-size: $font-size-base * 1.35;
$h5-font-size: $font-size-base * 1.25;
$h6-font-size: $font-size-base;
$display1-size: 2.5rem;
$display2-size: 2rem;
$display1-weight: 500;
/*$link-decoration: underline;*/
$btn-border-width: 2px;
$btn-font-family: $headings-font-family;
$btn-font-weight: 500;

$card-border-radius: 0;
$card-border-width:  1;
$card-border-color:   $light;

/* ======= IMPORT BOOTSTRAP, FONTS & OTHERS ======= */

@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

@import '../../../node_modules/bootstrap/scss/functions';
@import '../../../node_modules/bootstrap/scss/variables';
@import '../../../node_modules/bootstrap/scss/bootstrap';

@import '../../../node_modules/@fortawesome/fontawesome-free/scss/variables';
@import '../../../node_modules/@fortawesome/fontawesome-free/scss/fontawesome';
@import '../../../node_modules/@fortawesome/fontawesome-free/scss/regular';
@import '../../../node_modules/@fortawesome/fontawesome-free/scss/solid';
@import '../../../node_modules/@fortawesome/fontawesome-free/scss/brands';


/* ======= LAYOUT ======= */



.bg-cover {
	background-repeat: no-repeat;
	background-position: 50% 25%;
	background-size: cover;
}
.fullscreen {
	position: relative;
	overflow: hidden;
	padding-top: 3rem;
	padding-bottom: 3rem;
	@include media-breakpoint-up(lg) {
		padding-top: 0;
		padding-bottom: 0;
		min-width: 100%;
		width: 100vw;
		min-height: 45%;
		height: 45vh;
		max-width: 100%;
	}
	&#intro-hp {
		&:before {
			@extend .bg-cover;
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;		
			transform-origin: center;
			transform: scale(1.0);
			z-index: -1;
			pointer-events: none;
			background-image: linear-gradient(rgba(0,0,0,0.5), rgba(0,0,0,0.4)), url('../assets/img/img-intro.jpg');
		}
		&.is-visible:before {
			animation: zoom 15s linear 0s 1 forwards;
		}
	}
	
}

#cta {
	background: linear-gradient(rgba(0,0,0,.3), rgba($dark,.3)), url(img/bg-cta.jpg);
	/* Center and scale the image nicely */
	background-size: cover;
}




#intro-its {
	> .d-lg-flex {
		.flex-item {
			width: 100%;
			background: 0 none;
		}
		.flex-img {
			width: 100%;
			background: url(img/img-intro-its.jpg) no-repeat 50% 50%;
			min-height: 20em;
			background-size: cover;
			@include media-breakpoint-up(lg) {
				min-height: 30em;
			}
		}
	}
}


#intro-stampanti {
	> .d-lg-flex {
		.flex-item {
			width: 100%;
			background: 0 none;
			.flex-item-container {
				@include media-breakpoint-up(lg) {
					max-width: 550px;
				}
			}
		}
		.flex-img {
			width: 100%;
			background: url(img/img-intro-stampanti.jpg) no-repeat 50% 50%;
			min-height: 20em;
			background-size: cover;
			@include media-breakpoint-up(lg) {
				min-height: 30em;
			}
		}
	}
}


#intro-sale {
	> .d-lg-flex {
		.flex-item {
			width: 100%;
			background: 0 none;
			.flex-item-container {
				@include media-breakpoint-up(lg) {
					max-width: 550px;
				}
			}
		}
		.flex-img {
			width: 100%;
			background: url(img/img-intro-sale.jpg) no-repeat 50% 50%;
			min-height: 20em;
			background-size: cover;
			@include media-breakpoint-up(lg) {
				min-height: 30em;
			}
		}
	}
}


#intro-docuware {
	> .d-lg-flex {
		.flex-item {
			width: 100%;
			background: 0 none;
		}
		.flex-img {
			width: 100%;
			background: url(img/img-intro-docuware.jpg) no-repeat 50% 50%;
			min-height: 20em;
			background-size: cover;
			@include media-breakpoint-up(lg) {
				min-height: 30em;
			}
		}
	}
}



#intro-generica-its {
	> .d-lg-flex {
		.flex-item {
			width: 100%;
			background: 0 none;
		}
		.flex-img {
			width: 100%;
			background: url(img/intro-generica-its.jpg) no-repeat 50% 50%;
			min-height: 20em;
			background-size: cover;
			@include media-breakpoint-up(lg) {
				min-height: 30em;
			}
		}
	}
}

#intro-generica-stampanti {
	> .d-lg-flex {
		.flex-item {
			width: 100%;
			background: 0 none;
		}
		.flex-img {
			width: 100%;
			background: url(img/img-generica-pc.jpg) no-repeat 50% 50%;
			min-height: 20em;
			background-size: cover;
			@include media-breakpoint-up(lg) {
				min-height: 30em;
			}
		}
	}
}


#intro-generica-docuware {
	> .d-lg-flex {
		.flex-item {
			width: 100%;
			background: 0 none;
		}
		.flex-img {
			width: 100%;
			background: url(img/img-generica-docuware.jpg) no-repeat 50% 50%;
			min-height: 20em;
			background-size: cover;
			@include media-breakpoint-up(lg) {
				min-height: 30em;
			}
		}
	}
}

/* ======= UTILS ======= */

.bg-grey-transparent {
	background-color: rgba(228,228,228,.80);
}

.bg-arrow-white-left:after {
	@include media-breakpoint-up(md) {
		content: "";  
		border-top: 2rem solid transparent;
		border-bottom: 2rem solid transparent;
		border-right: 2rem solid #FFF;
		position: absolute;
		right: 0;
		top: 50%;
		transform: translateY(-50%);
	}
}


/* ======= TYPO ======= */

html {
	font-size: .875rem;
	@include media-breakpoint-up(md) {

		font-size: 1rem;
	}
}


h1,
.h1 {
	font-weight: 700;
}

h2,
.h2 {
	font-weight: 400;
}

h3,
.h3 {
	font-weight: 700;
}

h4,
.h4 {
	color: $secondary;
	font-weight: 700;
}

h5,
.h5 {
	font-weight: 700;
}

#advantages li {
    list-style: none;
    padding-left: 40px;
    background: url(img/spunta.png) no-repeat 0 16%;
    line-height: 1.4em;
     margin-bottom: 10px;
    text-align: left;
}

.text-shadow {
	text-shadow: 0 3px 6px rgba(0,0,0,.2);
}

.btn {
	text-transform: uppercase;
}

.card-title {color: $primary;}
#partner .card {background-color: transparent;}

#partner .card img {max-width: 60%; height: auto;}

.fa, .fas {color: $primary;}
.card-link {text-decoration: underline;}

.navbar-light .navbar-nav .nav-link.active {color: $primary; text-decoration: underline; font-weight: 700;}
.navbar-nav {
	text-transform: uppercase;
	font-weight: 600;
	color: $dark;
}

/* ======= FOOTER ======= */
footer#footer {
	background-color: $dark;
}

/* ======= FORM ======= */

$form_bg: transparent;

$form_div_width: 100%;

$form_label_width: 35%;
$form_label_color: #333;
$form_label_fontsize: 120%;

$form_input_color: #666;
$form_input_bg: #fff;
$form_input_border: 1px solid #ccc;
$form_input_width: 60%;
$form_input_padding: 1% 2%;
$form_input_fontsize: 120%;

$form_input_color_focus: #333;
$form_input_border_focus: 1px solid #999;
$form_input_bg_focus: #fff;

$form_privacy_fontsize: 80%; 

$form_invia_bg: $primary;
$form_invia_bg_hover: $dark;
$form_invia_color: #fff;
$form_invia_width: auto;
$form_invia_height: auto;
$form_invia_border: 1px solid #ffffff;
$form_invia_fontsize: 140%;

$form_error_color: red;


#nomesia_form {
	padding: 20px;
	margin: 10px 0;
	clear: both;
	background: $form_bg;
	fieldset {
		border: none;
		margin: 0;
		padding: 0;
	}
	input[type="hidden"] {
		display: none !important;
	}
	div {
		padding-bottom: 5px;
		margin: 5px 0px 5px 0;
		float: left;
		width: $form_div_width;
		&#submit_container{
			float: none;
			clear: both;
			margin: 0 auto;
		}
		select {
			width: $form_input_width;
			*width:130px;
		}
	}
	label {
		float: left;
		color: $form_label_color;
		font-size: $form_label_fontsize; 
		display: block;
		width: $form_label_width;
		*width: 100px; /* IE7 vuole necessariamente un valore in pixel */
		&.error {
			display: block;
			width: 90%!important;
			font-size: 80% !important;
			padding: 2px 5px;
			color: $form_error_color;
			background: none;
			border: none;
		}
	}
	textarea, input, select {
		color: $form_input_color;
		background: $form_input_bg;
		border: $form_input_border;
		font-size: $form_input_fontsize;
		padding: $form_input_padding;
		width: $form_input_width;
		*width: 100px;
		float: left;
		display:inline-block;
		-webkit-transition: border linear .2s, box-shadow linear .2s;
		-moz-transition: border linear .2s, box-shadow linear .2s;
		-o-transition: border linear .2s, box-shadow linear .2s;
		transition: border linear .2s, box-shadow linear .2s;
		&:focus	{
			color: $form_input_color_focus;
			border: $form_input_border_focus;
			background: $form_input_bg_focus;
			outline: 0;
			-webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 1px 1px 4px rgba(0,0,0,.4);
			-moz-box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 1px 1px 4px rgba(0,0,0,.4);
			box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 1px 1px 4px rgba(0,0,0,.4);
		}
	}
	textarea {
		height: 80px;
	}
	#privacy_container, #marketing_container {
		border: none;
		float: left;
		padding: 0 0 5px;
		label{
			float: left;
			font-weight: normal;
			display: block;
			width: 80%;
			font-size: $form_privacy_fontsize;
			line-height: 1.2em;
			clear: none;
		}
		input {
			margin: 5px 20px 0 0;
			padding: 0;
			background: none;
			border: none;
			width: 15px;
			float: left;
		}
	}
	#more_info_container {
		label {
			width: 100%;
			font-size: 110%;
		}
	}
	.button_form {
		background: $form_invia_bg;
		color: $form_invia_color;
		font-weight: bold;
		text-transform: uppercase;
		border: $form_invia_border;
		width: $form_invia_width;
		display: block;
		margin: 15px auto;
		padding: 8px 20px;
		height: $form_invia_height;
		font-size: $form_invia_fontsize;
		float: none;
		cursor: pointer;
		&:hover, &:focus {
			background: $form_invia_bg_hover;
			color: $form_invia_color;
		}
	}
	.checked {
		display: none!important;
	}
	.field_status{
		margin: 0;
		padding: 0;
		&.error{
			display: block;
		}
	}
	span.ast {
		color: red;
	}
}

@media \0screen {
	#nomesia_form textarea:focus, #nomesia_form input:focus, #nomesia_form select:focus{outline: thin dotted;}
}

@include media-breakpoint-down(xs) {

	#nomesia_form label {width: 100% !important;}
	#nomesia_form input {width: 100% !important;}
	#nomesia_form select {width: 100% !important;}
	#nomesia_form textarea {width: 100% !important;}
	#nomesia_form #privacy_container input, #nomesia_form #marketing_container input {width: 5% !important; margin: 0 4% 0 0;}
	#nomesia_form #privacy_container label, #nomesia_form #marketing_container label{width: 91% !important; padding: 0;}
	#nomesia_form .button_form {white-space: normal;}
}


/* ======= BACK TO TOP ======= */

#top {
	position: fixed;
	width: 40px;
	height: 40px;
	-moz-border-radius: 3px;
	-webkit-border-radius: 3px;
	border-radius: 3px;
	background: #49d7fb;
	bottom: 20px;
	right: 20px;
	cursor: pointer;
	#top-arrow {
		-o-transform: rotate(45deg);
		-moz-transform: rotate(45deg);
		-webkit-transform: rotate(45deg);
		transform: rotate(45deg);
		border-top: 5px solid #fff;
		border-left: 5px solid #fff;
		border-bottom: none;
		border-right: none;
		width: 10px;
		height: 10px;
		margin: 15px auto;
	}
}



